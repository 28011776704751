<template>
  <view-card-template :loaded="loaded">
    
    <template #cardTitle>
      Edit Award Types
    </template>

    <template #cardBody>
      <v-container class="pa-6">
        <v-row>
          <v-col
            cols="12"
          >
            <v-select
              v-model="selectedGlobalSportId"
              :items="sports"
              item-text="name"
              item-value="globalSportId"
              @change="getSportSpecificAwardTypes"
              label="Sport"
              solo-inverted
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col
            v-for="(awardType, index) in sportsAwardTypes"
            :key="index"
            cols="12"
            :sm="6"
            :md="4"
            :lg="3"
          >
            <v-checkbox
              v-model="awardType.enabled"
              @change="toggleAwardType(awardType)"
              :label="awardType.title"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"

export default {
  name: "AwardTypes",

  components: {
    ViewCardTemplate,
  },

  props: {
    //
  },

  data: () => ({
    loaded: false,
    
    sports: [],
    selectedGlobalSportId: null,

    sportsAwardTypes: [],
    maskedAwardTypeIdsByGlobalSportId: null,
  }),

  computed: {
    //
  },

  methods: {
    async toggleAwardType(awardType) {
      if(awardType.enabled) {
        const res = await Controllers.AwardTypeMaskController.AdminDeleteAwardTypeMask(awardType.id, this.selectedGlobalSportId)
        if(res && !res.hasError) {
          this.$root.showSnackbar(`"${awardType.title}" enabled`, 'success', 1500)
        }
        else {
          // Error handling
        }
      }
      else {
        const res = await Controllers.AwardTypeMaskController.AdminCreateAwardTypeMask(awardType.id, this.selectedGlobalSportId)
        if(res && !res.hasError) {
          this.$root.showSnackbar(`"${awardType.title}" disabled`, 'error', 1500)
        }
        else {
          // Error handling
        }
      }
    },

    async getSportSpecificAwardTypes(globalSportId) {
      const res = await Controllers.AwardTypeController.AdminListAwardTypes(globalSportId)

      if(res && !res.hasError) {
        let awardTypes = res.data
        awardTypes = awardTypes.sort((a, b) => a.title.localeCompare(b.title))

        const maskedAwardTypeIdsSet = this.maskedAwardTypeIdsByGlobalSportId[globalSportId]
        awardTypes.forEach(at => {
          at.enabled = maskedAwardTypeIdsSet != null ? !maskedAwardTypeIdsSet.has(at.id) : true
        })

        this.sportsAwardTypes = awardTypes
      }
      else {
        // Error handling
      }
    },
  },

  async created() {
    this.$root.updateTitle("Edit Award Types")
    
    let sports =  await this.$store.getters.conferenceSports
    this.sports = sports

    // Pre-fetch stat masks. (Since they aren't sport specific.)
    const res = await Controllers.AwardTypeMaskController.AdminListAwardTypeMasks()
    if(res && !res.hasError) {
      const masks = res.data
      const maskedAwardTypeIdsByGlobalSportId = {}
      masks.forEach(mask => {
        if(maskedAwardTypeIdsByGlobalSportId[mask.globalSportId] == null)
          maskedAwardTypeIdsByGlobalSportId[mask.globalSportId] = new Set()
        
        maskedAwardTypeIdsByGlobalSportId[mask.globalSportId].add(mask.awardTypeId)
      })

      this.maskedAwardTypeIdsByGlobalSportId = maskedAwardTypeIdsByGlobalSportId
    }
    else {
      // Error handling
    }

    this.loaded = true
  },
}
</script>

<style>

</style>